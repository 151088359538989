import React from 'react'

import SRModal from "../modal/modal"

const TermsOfSales = ({text}) => (
    <SRModal title={"General Terms and Conditions of Sale"} text={text}>
        <p>Due to the nature of a virtual conference and given the fact that there are no limitations to watch the full content of the conference at your convenience, our policy this year is not accepting any cancellations or refunds.</p>
    </SRModal>
)

export default TermsOfSales
