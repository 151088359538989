import React from "react"

import "./title.scss"

const Title = () => (
    <div className={"sr-main-title"}>
        <p className={"title"}>
        Welcome<br/>
            <strong>Register here for the 51st Union World Conference on Lung Health</strong>
        </p>

    </div>
)

export default Title
